.lang-image {
    height: 20px;
}

.rtl .sub_menu li {
    text-align: right;

}

.ltr .ml-auto,
.mx-auto {
    margin-left: 0 !important;
}

.rtl .navbar-expand-lg .navbar-nav .has_dropdown .sub_menu {

    border-radius: 10px 0px 10px 10px;

}

.rtl .navbar-expand-lg .navbar-nav .has_dropdown .drp_btn {
    right: 0;
}

.rtl .navbar-expand-lg .navbar-nav .has_dropdown .drp_btn:hover {
    padding-left: 8px;
}

@media only screen and (max-width: 600px) {
    .rtl .navbar-expand-lg .navbar-nav .has_dropdown .drp_btn {
        left: 0;
        display: none;
    }

   .rtl .navbar-expand-lg .navbar-nav .has_dropdown .sub_menu ul li a {
        text-align: right;
        line-height: 1.5;
        font-size: 15px;
      }
      .navbar-expand-lg .navbar-nav .has_dropdown .sub_menu ul li{
        width: 80%;
        margin-left: 10%;
      }
      .rtl .navbar-expand-lg .navbar-nav .has_dropdown .sub_menu ul li{
        margin-right: 10%;
      }
    .nav-item {
        min-height: 50px;
    }
    .lang-item {
       display: flex;;
       width: 80%;
       margin-left: 10%;
    }
   .rtl .lang-item {
     
        margin-right: 10%;
     }
    .lang-item a{
        width: 90%;
        margin-top: -5px;
    }
}