#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.98);
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
}



.d-none{
  display: none;
}
a{
  cursor: pointer;
}

.rtl{
  direction: rtl;
  font-family: iransans;
}
.ltr{
  direction: ltr;
}
.sob-basket{
  color: #fff;
  display: flex;
}

.sob-basket div {
  background-color: #f00;
  /* padding: 5px; */
  border-radius: 50%;
  width: 25px;
  height: 25px;
  text-align: center;
  font-size: 15px;
  margin-right: -10px;
}
.sob-basket i{
  font-size: 24px;

}

.rtl .sob-basket div {

  margin-right: -10px;
}
.ltr .sob-basket div {

  margin-left: -10px;
}

@media only screen and (max-width: 600px) {
  .sob-basket{
    color: var(--body-text-purple);
  }
  .sob-basket div {
    color: #fff;
  }

  .rtl .toggle-bar {
   rotate: 180deg;
}
.mobile-hidden{
  display: none !important;
}
.mobile-w-90{
  width: 90% !important;
  margin: auto;
}
}



