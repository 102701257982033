@media only screen and (max-width: 600px) {
   .sob-video-single{
     min-width: 100%;
    }
  }
@media only screen and (min-width: 600px) {
   .sob-video-single{
     min-width: 800px;
    }
  }



  .rtl .sob-uniqe-feature{
    text-align: right;
  }