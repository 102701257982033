.sob-home-video-player-container{
    width: 100%;
    height: 100%;
    background-color: rgb(91, 85, 85);
    z-index: 9999;
    position: absolute;
    opacity: 0.7;
    display: none;
    align-items: center;
    justify-content: center;

}
.sob-home-video-player-container a{
  color: #fff;

}

.sob-item-video:hover .sob-home-video-player-container{
  display: flex;
}

.sob-home-video-player-modal {
    display: block; /* Hidden by default */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.sob-home-video-player-8);
    justify-content: center;
    align-items: center;
    z-index: 999999;
  }
  
  .sob-home-video-player-modal-content {
    position: relative;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 600px;
    width: 90%;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.sob-home-video-player-2);
  }
  
  .sob-home-video-player-modal video {
    width: 100%;
    border-radius: 5px;
  }
  
  /* Close Button */
  .sob-home-video-player-close {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 24px;
    font-weight: bold;
    color: #a40f0f;
    cursor: pointer;
    z-index: 9999999;
    width: 30px;
    height: 30px;
    background-color: #fff;
    text-align: center;
    /* padding: 5px; */
    border-radius: 50%;
    /* padding-bottom: 6px; */
    line-height: 2rem;
  }
  
  .sob-home-video-player-close:hover {
    color: #000;
  }
