.rtl footer .top_footer .logo ,.rtl footer .top_footer p {
    text-align: right;
}
.rtl footer .top_footer .abt_side .news_letter_block .form-group .btn {
    right: inherit;
    left: 5px;
}
.rtl footer .top_footer .abt_side .news_letter_block .form-group .btn i{
    rotate: -120deg;
    
    display: block;
}

.rtl .links,.rtl .try_out{
    text-align: right;
}