.rtl .faq_section .faq_panel .card-header .icon_faq {
    left: 20px;
    right: auto;
  
}


.rtl .faq_section .faq_panel .card-header .btn, .rtl .faq_section .faq_panel .card-body{
   
    text-align: right;
}
