.rtl .service_left_side .section_title,
.rtl .service_left_side,
.rtl .service_right_side{
    text-align: right;
}
.service_detail_section {
    position: relative;
    padding: 100px 0 0 0;
}
.rtl .action_btn a p{
margin-right: 10px;
}

.rtl .side_contact_block .btn_main i {
    transform: rotate(0deg);
}

 .service_right_side {
    width: 350px;
    margin-top: 10px;
    margin-left: auto;
}