.rtl .profile_block .profile_side {
    
    border-radius: 0px 15px 15px 0px;
    text-align: right;
}
.rtl .signup_form {
  
    border-radius: 12px 0px 0px 12px;
}

#preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.98);
    z-index: 999999;
    display: flex;
    align-items: center;
    justify-content: center;
  }