.languages-modal-backdrop{
    background-color: rgb(50, 50, 50);
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 999999;
    top: 0;
    opacity: 0.98;

}
.languages-modal{
    background-color: rgb(252, 250, 250);
    width: 500px;
    height: 300px;
    position: fixed;
    z-index: 999999999;
    top: calc(50% - 150px);
    left: calc(50% - 250px);
    padding-top: 100px;
    border-radius: 7px;
   
}
.languages-modal .d-flex{
    align-items: center;
    align-content: center;
    justify-content: center;
   
   
}
.languages-modal .d-flex div{
   cursor: pointer;
   
   
}
.languages-modal .lang-image{
    width: 100px;
    height: 100px;
}

@media only screen and (max-width: 600px) {
    .languages-modal{
        background-color: rgb(252, 250, 250);
        width: 300px;
        height: 200px;
        position: fixed;
        z-index: 999999999;
        top: calc(50% - 100px);
        left: calc(50% - 150px);
        padding-top: 100px;
        border-radius: 7px;
       
    }
    .languages-modal .lang-image{
        width: 50px;
        height: 50px;
    }
    .languages-modal .d-flex{
        align-items: center;
        align-content: center;
        justify-content: center;
       
       
    }
    .languages-modal .d-flex div{
       cursor: pointer;
       flex: 0px;
       
    }
}