.rtl .banner_section .banner_text form .form-group button {
    position: absolute;
    left: 5px;
    top: 3px;
    padding: 9px 30px;
    right: auto;
}

.rtl .btn_main i {
    transform: rotate(180deg);
  
}